import React from "react";
import { orderBy } from "lodash";
import CurrencyFormat from "react-currency-format";
import { Segment, Icon, Table, Button } from "semantic-ui-react";
import NewTransactionModal from "./NewTransactionModal";

class UserTransactions extends React.Component {
  state = {
    pageNum: 1,
    transactions: [],
  };
  componentDidMount() {
    if (this.props.userData.transactions.length === 0) {
      const transaction = [
        {
          timestamp: "",
          type: "",
          amount: 0,
          available_balance: 0,
          account_value: 0,
        },
      ];
      this.setState({ transactions: transaction });
    } else {
      this.setState({
        transactions: orderBy(
          this.props.userData.transactions,
          ["timestamp"],
          ["desc"]
        ),
      });
    }
  }

  handlePagination = inc => {
    this.setState({ pageNum: this.state.pageNum + inc });
  };

  render() {
    return (
      <React.Fragment>
        <Segment>
          <h4>Movimientos</h4>
          <NewTransactionModal data={this.props.userData} />
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Fecha</Table.HeaderCell>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell>Monto</Table.HeaderCell>
                <Table.HeaderCell>Saldo posterior</Table.HeaderCell>
                <Table.HeaderCell>Valor cuenta</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.transactions
                .slice(
                  0 + 5 * (this.state.pageNum - 1),
                  5 + 5 * (this.state.pageNum - 1)
                )
                .map(transaction => (
                  <Table.Row
                    key={
                      "_" +
                      Math.random()
                        .toString(36)
                        .substr(2, 9)
                    }
                  >
                    <Table.Cell>{transaction.timestamp}</Table.Cell>
                    <Table.Cell>{transaction.type}</Table.Cell>
                    <Table.Cell>
                      <CurrencyFormat
                        value={transaction.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <CurrencyFormat
                        value={transaction.available_balance}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <CurrencyFormat
                        value={transaction.account_value}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <div align="center">
            <Button
              className={this.state.pageNum == 1 ? "disabled" : ""}
              icon
              onClick={() => this.handlePagination(-1)}
            >
              <Icon name="chevron left" />
            </Button>
            <Button
              className={
                this.state.pageNum >=
                this.props.userData.transactions.length / 5
                  ? "disabled"
                  : ""
              }
              icon
              onClick={() => this.handlePagination(1)}
            >
              <Icon name="chevron right" />
            </Button>
          </div>
        </Segment>
      </React.Fragment>
    );
  }
}

export default UserTransactions;
