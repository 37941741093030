import axios from "axios";
import { getToken } from "../auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_ENDPOINT,
});

const setAuthorization = token => {
  api.interceptors.request.use(config => {
    config.headers.Authorization = "Bearer " + token;
    return config;
  });
};

export default {
  get: (endpoint, queryParameters = {}) => {
    setAuthorization(getToken());
    return api.get(endpoint, queryParameters).then(res => res.data);
  },
  post: (endpoint, formData = {}) => {
    setAuthorization(getToken());
    return api.post(endpoint, formData).then(res => res.data);
  },
};
