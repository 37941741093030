import React, { Component } from "react";
import BehaviorSegment from "./BehaviorSegment";
import AssetsSegment from "./AssetsSegment";
import { Grid, Button } from "semantic-ui-react";
import axios from "../../utils/api/axios";
import PerformanceSegment from "./PerformanceSegment";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./style.css";
import moment from "moment";

class Dashboard extends Component {
  state = {
    loading: false,
    startDate: null,
    endDate: null,
    behavior: {
      account_value: 0,
      active_users: 0,
      funded: 0,
      users: 0,
      withdrawals: 0,
    },
    assets: {
      funded: 0,
      in_funding: 0,
      available: 0,
      funded_listings: 0,
      number_of_panels: 0,
      energy_production: 0,
    },
    performance: {
      COkgs: 0,
      average_rate: 0,
      drivenKms: 0,
      gasLiter: 0,
      kgsRecycled: 0,
      plantedTrees: 0,
      status: 0,
      totalInvested: 0,
      energy_produced: 0,
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  filterData = (start, end) => {
    let startDateF = moment(start).format("DD-MM-Y");
    let endDateF = moment(end).format("DD-MM-Y");
    this.fetchData({ startDateF, endDateF });
  };

  fetchData = filter => {
    this.setState({ loading: true });
    let query = "";
    if (!!filter) {
      query = `?startDate=${filter.startDateF}&endDate=${filter.endDateF}`;
    }
    axios
      .get("/behavior" + query)
      .then(response => {
        let { behavior } = response;
        return this.setState(prevState => ({
          behavior: { ...prevState.behavior, ...behavior },
        }));
      })
      .then(() => {
        this.setState({ loading: false });
      });

    axios
      .get("/assets" + query)
      .then(response => {
        let { assets } = response;
        return this.setState(prevState => ({
          assets: { ...prevState.assets, ...assets },
        }));
      })
      .then(() => {
        this.setState({ loading: false });
      });

    axios
      .get("/performance" + query)
      .then(response => {
        let performance = response;
        return this.setState(prevState => ({
          performance: { ...prevState.performance, ...performance },
        }));
      })
      .then(performance => {
        this.setState({ loading: false });
      });
  };
  dateChanged = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
    if (!!!startDate && !!!endDate) {
      this.fetchData();
    }
  };
  render() {
    return (
      <div className="dashboard pushed">
        <React.Fragment>
          Filtro:
          <DateRangePicker
            startDate={this.state.startDate}
            startDateId="starting"
            endDate={this.state.endDate}
            endDateId="ending"
            isOutsideRange={() => false}
            onDatesChange={change => this.dateChanged(change)}
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            showClearDates
          />
          <Button
            disabled={!!!this.state.startDate || !!!this.state.endDate}
            className="filter_button"
            onClick={() =>
              this.filterData(this.state.startDate, this.state.endDate)
            }
          >
            Filtrar
          </Button>
          <Grid stackable columns="2" className="main_grid">
            <Grid.Column width={8}>
              <BehaviorSegment
                behavior={this.state.behavior}
                loading={this.state.loading}
              />
              <PerformanceSegment
                performance={this.state.performance}
                loading={this.state.loading}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <AssetsSegment
                assets={this.state.assets}
                loading={this.state.loading}
              />
            </Grid.Column>
          </Grid>
        </React.Fragment>
      </div>
    );
  }
}

export default Dashboard;
