import React from "react";
import { orderBy } from "lodash";
import { Segment, Icon, Table, Button } from "semantic-ui-react";
import CurrencyFormat from "react-currency-format";
import NewInvestmentModal from "./NewInvestmentModal";

class UserInvestmentsTable extends React.Component {
  state = {
    pageNum: 1,
    investments: [],
  };

  componentDidMount() {
    if (this.props.userData.investments.length === 0) {
      const investment = [
        {
          amount: 0,
          id: "",
          listing: "",
          status_id: "",
          investment_date: "",
          initial_annual_rate: "",
          current_annual_rate: "",
          total_payment: "",
          listing: { name: "" },
        },
      ];
      this.setState({ investments: investment });
    } else {
      this.setState({
        investments: orderBy(
          this.props.userData.investments,
          ["investment_date"],
          ["desc"]
        ),
      });
    }
  }

  handlePagination = inc => {
    this.setState({ pageNum: this.state.pageNum + inc });
  };

  render() {
    return (
      <React.Fragment>
        <Segment>
          <h4>Inversiones</h4>
          <NewInvestmentModal data = { this.props.userData } />
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Cantidad</Table.HeaderCell>
                <Table.HeaderCell>Tasa Inicial</Table.HeaderCell>
                <Table.HeaderCell>Tasa Actual</Table.HeaderCell>
                <Table.HeaderCell>Rendimiento</Table.HeaderCell>
                <Table.HeaderCell>Fecha de inversión</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.investments
                .slice(
                  0 + 5 * (this.state.pageNum - 1),
                  5 + 5 * (this.state.pageNum - 1)
                )
                .map(investment => (
                  <Table.Row key={investment.status_id * 100 + investment.id}>
                    <Table.Cell>{investment.id}</Table.Cell>
                    <Table.Cell>{investment.listing.name}</Table.Cell>
                    <Table.Cell>{investment.status_id}</Table.Cell>
                    <Table.Cell>
                      <CurrencyFormat
                        value={investment.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </Table.Cell>
                    <Table.Cell>{investment.initial_annual_rate}</Table.Cell>
                    <Table.Cell>{investment.current_annual_rate}</Table.Cell>
                    <Table.Cell>
                      <CurrencyFormat
                        value={investment.total_payment}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </Table.Cell>
                    <Table.Cell>{investment.investment_date}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <div align="center">
            <Button
              className={this.state.pageNum == 1 ? "disabled" : ""}
              icon
              onClick={() => this.handlePagination(-1)}
            >
              <Icon name="chevron left" />
            </Button>
            <Button
              className={
                this.state.pageNum >= this.props.userData.investments.length / 5
                  ? "disabled"
                  : ""
              }
              icon
              onClick={() => this.handlePagination(1)}
            >
              <Icon name="chevron right" />
            </Button>
          </div>
        </Segment>
      </React.Fragment>
    );
  }
}

export default UserInvestmentsTable;
