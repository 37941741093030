import React from "react";

const UserLoginList = ({ logins }) => {
  if (logins.length === 0) {
    logins = [
      {
        id: 0,
        timestamp: "Este Usuario no ha iniciado Sesión",
      },
    ];
  }
  return (
    <ul className="logins">
      {logins.map(login => (
        <li key={500 + login.timestamp}>{login.timestamp}</li>
      ))}
    </ul>
  );
};
export default UserLoginList;
