const FUNDING_STATUS_TEXT = {
  11: "EN FONDEO",
  12: "FONDEADO",
};

const FUNDING_STATUS_COLOR = {
  11: "green",
  12: "yellow",
};

const FUNDING_STATUS = {
  IN_FUNDING: 11,
  FUNDED: 12,
};

const WITHDRAWAL_STATUS = {
  PROCESSING: 3,
  COMPLETED: 4,
};

const INVESTMENT = {
  COMPLETED: 14,
};
const WITHDRAWAL = {
  ACTIVE: 3,
  INACTIVE: 4,
};

module.exports = {
  JWT_STORAGE_KEY: "storage/jwt",
  FUNDING_STATUS_TEXT,
  FUNDING_STATUS_COLOR,
  FUNDING_STATUS,
  WITHDRAWAL_STATUS,
  INVESTMENT,
  WITHDRAWAL,
};
