import React, { Fragment } from "react";
import CurrencyFormat from "react-currency-format";
import { Segment, Grid, Icon } from "semantic-ui-react";

const PerformanceSegment = ({ performance, loading }) => (
  <Fragment>
    <Segment loading={loading} textAlign="center" className="performance">
      <h3>Desempeño</h3>
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={8}>
            <div className="utilities">
              <h4>Utilidades Producidas</h4>
              <CurrencyFormat
                className="mainData"
                value={parseFloat(performance.totalEarnings).toFixed(2)}
                displayType="text"
                thousandSeparator={true}
                prefix="$"
              />
              <div className="average">
                {(100 * performance.average_rate).toFixed(2)}%
              </div>
            </div>
            <div>
              <h4>KW/H Producidos</h4>
              <CurrencyFormat
                className="mainData"
                value={performance.energy_produced.toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div className="performance-cat main">
              <Icon name="recycle" size="big" />
              <CurrencyFormat
                className="amount"
                value={performance.COkgs.toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
              <span className="label">Kgs de CO2 </span>
            </div>
            <div className="performance-cat">
              <Icon name="car" size="big" />
              <CurrencyFormat
                className="amount"
                value={performance.drivenKms.toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
              <span className="label">Kms. Manejados </span>
            </div>
            <div className="performance-cat">
              <Icon name="tree" size="big" />
              <CurrencyFormat
                className="amount"
                value={performance.plantedTrees.toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
              <span className="label">Arboles Plantados </span>
            </div>
            <div className="performance-cat">
              <Icon name="trash" size="big" />
              <CurrencyFormat
                className="amount"
                value={performance.kgsRecycled.toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
              <span className="label">Kgs. Reciclados </span>
            </div>
            <div className="performance-cat">
              <Icon name="industry" size="big" />
              <CurrencyFormat
                className="amount"
                value={performance.gasLiter.toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
              <span className="label">Litros de Gasolina </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </Fragment>
);

export default PerformanceSegment;
