import React, { Component } from "react";
import { Modal, Button, Icon, Form, Message, Select } from "semantic-ui-react";
import SweetAlert from "sweetalert-react";

import Request from "../../utils/api/axios";

const lobOptions = [
  { value: 1, key: 1, text: "Plaza Comercial" },
  { value: 2, key: 2, text: "Restaurante" },
  { value: 3, key: 3, text: "Abarrotes" },
  { value: 4, key: 4, text: "Gimnasio" },
  { value: 5, key: 5, text: "Pastelería y Panificadora" },
  { value: 6, key: 6, text: "Uniformes" },
  { value: 7, key: 7, text: "Escuela" },
  { value: 8, key: 8, text: "Farmacia" },
  { value: 9, key: 9, text: "Spa y Salón de belleza" },
  { value: 10, key: 10, text: "Tecnología y Diseño" },
];

const states = [
  { value: 1, key: 1, text: "Aguascalientes" },
  { value: 2, key: 2, text: "Baja California" },
  { value: 3, key: 3, text: "Baja California Sur" },
  { value: 4, key: 4, text: "Campeche" },
  { value: 5, key: 5, text: "Chiapas" },
  { value: 6, key: 6, text: "Chihuahua" },
  { value: 7, key: 7, text: "Coahuila de Zaragoza" },
  { value: 8, key: 8, text: "Colima" },
  { value: 9, key: 9, text: "Durango" },
  { value: 10, key: 10, text: "Estado de México" },
  { value: 11, key: 11, text: "Guanajuato" },
  { value: 12, key: 12, text: "Guerrero" },
  { value: 13, key: 13, text: "Hidalgo" },
  { value: 14, key: 14, text: "Jalisco" },
  { value: 15, key: 15, text: "Michoacán de Ocampo" },
  { value: 16, key: 16, text: "Morelos" },
  { value: 17, key: 17, text: "Nayarit" },
  { value: 18, key: 18, text: "Nuevo León" },
  { value: 19, key: 19, text: "Oaxaca" },
  { value: 20, key: 20, text: "Puebla" },
  { value: 21, key: 21, text: "Querétaro" },
  { value: 22, key: 22, text: "Quintana Roo" },
  { value: 23, key: 23, text: "San Luis Potosí" },
  { value: 24, key: 24, text: "Sinaloa" },
  { value: 25, key: 25, text: "Sonora" },
  { value: 26, key: 26, text: "Tabasco" },
  { value: 27, key: 27, text: "Tamaulipas" },
  { value: 28, key: 28, text: "Tlaxcala" },
  { value: 29, key: 29, text: "Veracruz de Ignacio de la Llave" },
  { value: 30, key: 30, text: "Yucatán" },
  { value: 31, key: 31, text: "Zacatecas" },
];

class NewSolarCustomerModal extends Component {
  state = {
    open: false,
    loading: false,
    error: false,
    name: "",
    lob: "",
    yearsOperating: "",
    funFact: "",
    logo: "",
    message: "",
    locX: "",
    locY: "",
    street: "",
    extNumber: "",
    district: "",
    county: "",
    stateId: "",
    zip: "",
    showAlert: false,
  };

  open = () => {
    this.clearFields();
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false });
  };

  handleChange = ({ target }, dropOptions) => {
    if (!!!dropOptions) {
      this.setState({ [target.name]: target.value });
    } else {
      this.setState({ [dropOptions.name]: dropOptions.value });
    }
  };

  handleFile = ({ target }) => {
    const data = new FormData();
    this.setState({ logo: target.files[0] });
  };

  handleCreate = () => {
    this.setState({ loading: true });
    let route = "business";
    const data = new FormData();
    data.append("name", this.state.name);
    data.append("lob", this.state.lob);
    data.append("years_operating", this.state.yearsOperating);
    data.append("fun_fact", this.state.funFact);
    data.append("loc_x", this.state.locX);
    data.append("loc_y", this.state.locY);
    data.append("logo", this.state.logo);
    data.append("street", this.state.street);
    data.append("district", this.state.district);
    data.append("county", this.state.county);
    data.append("ext_number", this.state.extNumber);
    data.append("state_id", this.state.stateId);
    data.append("zip", this.state.zip);
    Request.post(route, data)
      .then(data => {
        this.setState({
          loading: false,
          error: false,
          open: false,
          showAlert: true,
        });
      })
      .catch(err => {
        let message = "Error en el servicio";
        if (err.response.status === 404) {
          message = "Verifica tus credenciales";
        } else if (err.response.status === 400) {
          message = err.response.data;
        }
        this.setState({
          loading: false,
          error: true,
          message,
        });
      });
  };

  clearFields = () => {
    this.setState({
      name: "",
      lob: "",
      yearsOperating: "",
      funFact: "",
      logo: "",
      message: "",
      locX: "",
      locY: "",
      street: "",
      district: "",
      county: "",
      stateId: "",
      zip: "",
    });
  };

  render() {
    return (
      <React.Fragment>
        <Button onClick={this.open} circular floated="right" color="green">
          <Icon name="plus" />
          Cliente Solar
        </Button>
        <Modal open={this.state.open} size="small" onClose={this.close}>
          <Modal.Header>Crear Cliente Solar</Modal.Header>
          <Modal.Content>
            <Form loading={this.state.loading} error={this.state.error}>
              <Form.Group>
                <Form.Field width={10}>
                  <label>Nombre</label>
                  <input
                    value={this.state.name}
                    placeholder="Plaza del sol"
                    onChange={this.handleChange}
                    name="name"
                  />
                </Form.Field>
                <Form.Field width={6}>
                  <label>Giro</label>
                  <Select
                    options={lobOptions}
                    onChange={this.handleChange}
                    placeholder="Giro"
                    name="lob"
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <label>Dato Curioso</label>
                <input
                  value={this.state.funFact}
                  placeholder="Ej. Este es un buen dato al respecto"
                  onChange={this.handleChange}
                  name="funFact"
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Años de operación</label>
                  <input
                    value={this.state.yearsOperating}
                    placeholder="Ej. 3"
                    onChange={this.handleChange}
                    name="yearsOperating"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Loc X</label>
                  <input
                    value={this.state.locX}
                    placeholder="19.4978"
                    onChange={this.handleChange}
                    name="locX"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Loc Y</label>
                  <input
                    value={this.state.locY}
                    placeholder=" -99.1269 19"
                    onChange={this.handleChange}
                    name="locY"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={7}>
                  <label>Calle</label>
                  <input
                    value={this.state.street}
                    placeholder="Avenida Industrial"
                    onChange={this.handleChange}
                    name="street"
                  />
                </Form.Field>
                <Form.Field width={2}>
                  <label>Numero</label>
                  <input
                    value={this.state.extNumber}
                    placeholder="123"
                    onChange={this.handleChange}
                    name="extNumber"
                  />
                </Form.Field>
                <Form.Field width={7}>
                  <label>Colonia</label>
                  <input
                    value={this.state.district}
                    placeholder="Pedregal"
                    onChange={this.handleChange}
                    name="district"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Municipio</label>
                  <input
                    value={this.state.county}
                    placeholder="Guadalajara"
                    onChange={this.handleChange}
                    name="county"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Estado</label>
                  <Select
                    options={states}
                    onChange={this.handleChange}
                    placeholder="Estado"
                    name="stateId"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Codigo Postal</label>
                  <input
                    value={this.state.zip}
                    placeholder="77777"
                    onChange={this.handleChange}
                    name="zip"
                  />
                </Form.Field>
              </Form.Group>
              <Button as="label" htmlFor="file" type="button">
                Sube un logo
              </Button>
              {!!this.state.logo && !!this.state.logo.name
                ? this.state.logo.name
                : "Archivo no seleccionado"}
              <input
                type="file"
                name="logo"
                id="file"
                style={{ display: "none" }}
                onChange={this.handleFile}
              />
              <Message error header="Error" content={this.state.message} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button.Group size="large">
              <Button onClick={this.close} color="red">
                Cancelar
              </Button>
              <div className="or" data-text="o"></div>
              <Button type="submit" onClick={this.handleCreate} positive>
                Guardar
              </Button>
            </Button.Group>
          </Modal.Actions>
        </Modal>
        <SweetAlert
          show={this.state.showAlert}
          title="Cliente Solar Creado"
          type="success"
          onConfirm={() => this.setState({ showAlert: false })}
        />
      </React.Fragment>
    );
  }
}

export default NewSolarCustomerModal;
