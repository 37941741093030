import React, { Component } from "react";
import Layout from "./components/Layout";
import Login from "./components/Login";
import { hasValidToken, getToken } from "./utils/auth.js";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "react-apollo";
import { setContext } from "apollo-link-context";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_BASE_ENDPOINT}/graphql/bo`,
  useGETForQueries: true,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

class App extends Component {
  render() {
    const auth = () => {
      if (hasValidToken()) {
        return (
          <div className="App">
            <ApolloProvider client={client}>
              <Layout />
            </ApolloProvider>
          </div>
        );
      } else {
        return (
          <div className="App">
            <Login />
          </div>
        );
      }
    };
    return <Router>{auth()}</Router>;
  }
}

export default App;
