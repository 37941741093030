import React from "react";
import { Segment } from "semantic-ui-react";
import UserLoginList from "./UserLoginList";
import { WITHDRAWAL } from "../../utils/constants.js";
import CurrencyFormat from "react-currency-format";

const sumValues = values => values.reduce((sum, curr) => sum + curr.amount, 0);

const UserBehavior = ({ userData }) => {
  let { investments, withdrawals } = userData;
  let activeWithdrawals = [{ amount: 0 }];
  if (investments.length === 0) {
    investments = [{ amount: 0 }];
  }
  if (withdrawals.length === 0) {
    withdrawals = [{ status_id: 3, amount: 0 }];
  } else {
    activeWithdrawals = withdrawals.filter(
      ({ status_id }) => status_id === WITHDRAWAL.ACTIVE
    );
  }
  return (
    <React.Fragment>
      <Segment.Group className="no_border no_padding investment_info">
        <Segment.Group horizontal className="no_border">
          <Segment className="no_padding">
            <h4>Capital Fondeado</h4>
            <b>
              <CurrencyFormat
                value={sumValues(investments)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </b>
          </Segment>
          <Segment>
            <h4>Capital Retirado</h4>
            En proceso:{" "}
            <b>
              <CurrencyFormat
                value={sumValues(activeWithdrawals)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </b>{" "}
            | Total:{" "}
            <b>
              <CurrencyFormat
                value={sumValues(withdrawals)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </b>
          </Segment>
        </Segment.Group>
        <Segment textAlign="center" className="no_padding table_segment">
          <h4>Ultimos 5 inicios de sesión</h4>
          <UserLoginList logins={userData.logins} />
        </Segment>
      </Segment.Group>
    </React.Fragment>
  );
};

export default UserBehavior;
