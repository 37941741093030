import React, { Component } from "react";
import { Input, Form } from "semantic-ui-react";

class SearchUser extends Component {
  state = { searchInput: "" };
  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };
  handleClick = event => {
    this.props.setUserSearchQuery(this.state.searchInput);
  };
  render() {
    return (
      <Form onSubmit={this.handleClick}>
        <Input
          value={this.state.searchInput}
          onChange={this.handleChange}
          id="searchInput"
          action={{ icon: "search", onClick: this.handleClick }}
          placeholder="Buscar usuario..."
        />
      </Form>
    );
  }
}

export default SearchUser;
