import React, { Component } from "react";
import { Menu, Image, Icon, Sidebar } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import logo from "../../images/lumio_beta.png";

class MainMenu extends Component {
  state = { activeItem: "home" };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    this.props.selection(name);
  };

  render() {
    const { activeItem } = this.state;

    return (
      <Sidebar as={Menu} secondary vertical visible id="main_menu">
        <Image className="logo" src={logo} size="small" name="logo" centered />
        <Menu.Item
          as={Link}
          to="/"
          name="resumen"
          active={activeItem === "resumen"}
          onClick={this.handleItemClick}
        >
          <Icon name="area graph" size="large" />
          Resumen
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/users"
          name="usuarios"
          active={activeItem === "usuarios"}
          onClick={this.handleItemClick}
        >
          <Icon name="users" size="large" />
          Usuarios
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/listings"
          name="proyectos"
          active={activeItem === "proyectos"}
          onClick={this.handleItemClick}
        >
          <Icon name="list" size="large" />
          Proyectos
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/solar-customers"
          name="Clientes Solares"
          active={activeItem === "solares"}
          onClick={this.handleItemClick}
        >
          <Icon name="sun" size="large" />
          Clientes Solares
        </Menu.Item>

        <Menu.Item
          as={Link}
          to="/logout"
          name="cerrar sesion"
          className="bottom"
        >
          <Icon name="log out" size="large" />
          Cerrar sesion
        </Menu.Item>
      </Sidebar>
    );
  }
}

export default withRouter(MainMenu);
