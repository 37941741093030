import React, { Component } from "react";
import { hasValidToken, deleteToken } from "./auth.js";
import { withRouter } from "react-router-dom";

class Logout extends Component {
  render() {
    if (hasValidToken()) {
      this.props.history.replace({ pathname: "/" });
      deleteToken();
      window.location.reload();
      return <div id="logout">Sesión Terminada</div>;
    } else {
      return <div>Aún no has iniciado sesión</div>;
    }
  }
}

export default withRouter(Logout);
