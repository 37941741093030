import React, { Fragment } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { Doughnut as DoughnutChart } from "react-chartjs-2";
import CurrencyFormat from "react-currency-format";

const prepareChartData = values => {
  const labels = values.map(value => value.name);
  const data = values.map(value => value.amount);
  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: ["#FFC001", "#374bce", "#3ba834"],
        hoverBackgroundColor: ["#CC9900", "#2C3CA4", "#308629"],
      },
    ],
  };
};

const options = {
  maintainAspectRatio: true,
  responsive: true,
  legend: {
    display: false,
    position: "bottom",
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        let label = new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(data.datasets[0].data[tooltipItem.index]);
        return label;
      },
    },
  },
};

const AssetsSegment = ({ assets, loading }) => (
  <Fragment>
    <Segment loading={loading} textAlign="center" className="assets_segment">
      <h3>Activos</h3>
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={8}>
            <h3>
              <CurrencyFormat
                value={
                  (parseFloat(assets.funded) +
                  parseFloat(assets.available) +
                  parseFloat(assets.in_funding)).toFixed(2)
                }
                displayType="text"
                thousandSeparator={true}
                prefix="$"
              />
            </h3>
            <div className="chart">
              <DoughnutChart
                data={prepareChartData([
                  { name: "CAPITAL ACTIVO", amount: assets.funded },
                  { name: "CAPITAL EN FONDEO", amount: assets.in_funding },
                  { name: "CAPITAL LIQUIDO", amount: assets.available },
                ])}
                options={options}
                width={170}
                height={170}
              />
            </div>
            <div className="labels">
              <div className="label">
                <div className="label_color funded" />
                <b>CAPITAL ACTIVO </b>
                <CurrencyFormat
                  value={parseFloat(assets.funded).toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$"
                />
              </div>
              <div className="label">
                <div className="label_color in_funding" />
                <b>CAPITAL EN FONDEO </b>
                <CurrencyFormat
                  value={parseFloat(assets.in_funding).toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$"
                />
              </div>
              <div className="label">
                <div className="label_color available" />
                <b>CAPITAL LIQUIDO </b>
                <CurrencyFormat
                  value={parseFloat(assets.available).toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$"
                />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={8} verticalAlign="middle">
            <Grid.Row>
              <div className="asset_detail separator">
                <span className="asset_data">{assets.funded_listings}</span>
                <h4>PROYECTOS</h4>
              </div>
              <div className="asset_detail separator">
                <span className="asset_data">{assets.number_of_panels}</span>
                <h4>PANELES</h4>
              </div>
              <div className="asset_detail">
                <span className="asset_data">{assets.energy_production}KW</span>
                <h4>POTENCIA</h4>
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </Fragment>
);

export default AssetsSegment;
