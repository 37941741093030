import React, { Component } from "react";
import {
  Header,
  Table,
  Button,
  Icon,
  Label,
  Dimmer,
  Loader,
  Segment,
} from "semantic-ui-react";

import Request from "../../utils/api/axios";
import { withRouter, Link } from "react-router-dom";
import NewSolarCustomerModal from "./NewSolarCustomerModal.js";

import CurrencyFormat from "react-currency-format";

import {
  FUNDING_STATUS_TEXT,
  FUNDING_STATUS_COLOR,
} from "../../utils/constants";

class SolarCustomers extends Component {
  state = {
    business: [],
    loading: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    Request.get("/business")
      .then(data => {
        this.setState({
          business: data.business,
          loading: false,
        });
      })
      .catch(err => {
        console.log("error");
      });
  }

  render() {
    return (
      <div className="main_container">
        <Segment loading={this.state.loading}>
          <Header as="h3">Cliente Solar</Header>
          <NewSolarCustomerModal data={this.props.userData} />
          <Table celled columns={6}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Años de Operación</Table.HeaderCell>
                <Table.HeaderCell>Dirección</Table.HeaderCell>
                <Table.HeaderCell>Dato curioso</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.business.map(business => (
                <Table.Row
                  key={`listings-page-active-table-row-${business.id}`}
                >
                  <Table.Cell>{business.name}</Table.Cell>
                  <Table.Cell>{business.years_operating}</Table.Cell>
                  <Table.Cell>
                    {!!business.address
                      ? `${business.address.street}, ${business.address.district}, ${business.address.county}`
                      : ""}
                  </Table.Cell>
                  <Table.Cell>{business.fun_fact}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

export default withRouter(SolarCustomers);
