const createStorage = storage => ({
  getItem: key => storage.getItem(key),

  setItem: (key, value) => storage.setItem(key, value),

  removeItem: key => storage.removeItem(key),
});

const LocalStorage = createStorage(window.localStorage);
const SessionStorage = createStorage(window.sessionStorage);

export { LocalStorage, SessionStorage };
