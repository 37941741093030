import React, { Component } from "react";
import { Header, Table, Button, Icon, Label, Segment } from "semantic-ui-react";

import Request from "../../utils/api/axios";
import { withRouter, Link } from "react-router-dom";

import CurrencyFormat from "react-currency-format";

import {
  FUNDING_STATUS_TEXT,
  FUNDING_STATUS_COLOR,
} from "../../utils/constants";
import "./styles.css";

class Listings extends Component {
  state = {
    listings: [],
    loading: true
  };

  componentDidMount() {
    Request.get("/listings")
      .then(data => {
        this.setState({
          listings: data.listings,
          loading: false
        });
      })
      .catch(err => {
        console.log("error");
      });
  }

  render() {
    return (
      <div className="main_container">
        <Header as="h3">Investment Listings</Header>
        <Segment loading={this.state.loading}>
          <Button
            circular
            floated="right"
            color="green"
            as={Link}
            to="/listings/new"
          >
            <Icon name="plus" />
            Listing
          </Button>
          <Table celled columns={6}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Listing</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Funded</Table.HeaderCell>
                <Table.HeaderCell>Total Investment</Table.HeaderCell>
                <Table.HeaderCell># Investors</Table.HeaderCell>
                <Table.HeaderCell>Annual Rate</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.listings.map(listing => (
                <Table.Row key={`listings-page-active-table-row-${listing.id}`}>
                  <Table.Cell>{listing.name}</Table.Cell>
                  <Table.Cell>
                    <Label
                      color={FUNDING_STATUS_COLOR[listing.funding_status_id]}
                    >
                      {FUNDING_STATUS_TEXT[listing.funding_status_id]}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <CurrencyFormat
                      value={listing.raised_money}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <CurrencyFormat
                      value={listing.total_investment}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Table.Cell>
                  <Table.Cell>{listing.number_of_investors}</Table.Cell>
                  <Table.Cell>
                    {(listing.initial_annual_rate * 100).toFixed(2)}%
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

export default withRouter(Listings);
