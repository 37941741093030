import React, { Component } from "react";
import { Segment, Placeholder, Icon, Header, Grid } from "semantic-ui-react";
import axios from "../../utils/api/axios";
import UserAccountValueSegment from "./UserAccountValueSegment";
import UserBehaviorSegment from "./UserBehaviorSegment";
import UserInvestmentsTable from "./UserInvestmentsTable";
import UserTransactions from "./UserTransactions";
import PerformanceSegment from "../Dashboard/PerformanceSegment";

const LoadingPlaceholder = () => (
  <Segment className="balance_info">
    <Placeholder>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </Segment>
);

const SegmentPlaceholder = ({ iconName, message }) => (
  <React.Fragment>
    <Segment placeholder>
      <Header icon>
        <Icon name={iconName} />
        {message}
      </Header>
    </Segment>
  </React.Fragment>
);

const AccountValueSegment = ({ userData }) => (
  <React.Fragment>
    <Segment size="small" className="balance_info">
      <UserAccountValueSegment data={userData} />
    </Segment>
  </React.Fragment>
);

class Users extends Component {
  state = {
    performance: {
      COkgs: 0,
      average_rate: 0,
      drivenKms: 0,
      gasLiter: 0,
      kgsRecycled: 0,
      plantedTrees: 0,
      status: 0,
      totalInvested: 0,
      energy_produced: 0,
    },
    loading: false,
  };
  // you can access all the user data from this class
  // but

  componentWillReceiveProps(nextProps) {
    if (nextProps.userData && nextProps.userData !== this.props.userData) {
      axios
        .get(`user/${nextProps.userData.id}/performance/`)
        .then(response => {
          let performance = response;
          return this.setState(prevState => ({
            performance: { ...prevState.performance, ...performance },
          }));
        })
        .then(performance => {
          this.setState({ loading: false });
        });
    }
  }

  render() {
    if (this.props.status === "loading") {
      return <LoadingPlaceholder />;
    }
    if (this.props.status === "error") {
      return (
        <div className="message">
          <SegmentPlaceholder message={this.props.message} iconName="plug" />
        </div>
      );
    }
    if (this.props.status === "not_found") {
      return (
        <div className="message">
          <SegmentPlaceholder
            message="Usuario no encontrado"
            iconName="exclamation triangle"
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        {/* Add your divs or segments with your user info, for example this segment contains the User balance info section */}
        <Grid stackable columns="2" className="main_grid">
          <Grid.Column width={8}>
            <AccountValueSegment userData={this.props.userData} />
            <PerformanceSegment
              performance={this.state.performance}
              loading={this.state.loading}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <UserBehaviorSegment userData={this.props.userData} />
          </Grid.Column>
          <Grid.Column width={16}>
            <UserInvestmentsTable userData={this.props.userData} />
          </Grid.Column>
          <Grid.Column width={16}>
            <UserTransactions userData={this.props.userData} />
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Users;
