import React, { Component } from "react";
import { Modal, Button, Icon, Form, Message, Dropdown } from "semantic-ui-react";
import SweetAlert from "sweetalert-react";
import Request from "../../utils/api/axios";

class NewInvestmentModal extends Component {
  state = {
    open: false,
    loading: false,
    error: false,
    amount: "",
    listingId: null,
    message: "",
    showAlert: false,
    options: [],
  }

  componentDidMount() {
    Request.get("listings").then((data) => {
      let opt = [];
      for (var i = 0; i < data.listings.length; i++) {
        if(data.listings[i].funding_status_id == 11) {
          opt.push({
            key: data.listings[i].id,
            text: data.listings[i].name,
            value: data.listings[i].id
          });
        }
      }
      this.setState({options: opt});
    });
  }

  open = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  handleChange = ({target}) => {
    this.setState({ [target.name]: target.value });
  };

  onDropdownChange = (e, data) => {
    this.setState({ listingId: data.value });
  }
  
  handleCreate = () => {
    this.setState({ loading: true });
    let route = "users/" + this.props.data.id + "/investments";
    Request.post(route, {
      amount: this.state.amount,
      listingId: this.state.listingId,
    })
      .then((data) => {
        this.setState({
          loading: false,
          error: false,
          open: false,
          showAlert: true,
        });
      })
      .catch((err) => {
        let message = "Error en el servicio";
        if (err.response.status === 404) {
          message = "Verifica tus credenciales";
        } else if (err.response.status === 400) {
          if ('error' in err.response.data) {
            message = err.response.data.error;
          } else {
            message = err.response.data;
          }
        }
        this.setState({
          loading: false,
          error: true,
          message,
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Button onClick={this.open} circular floated="right" color="green">
          <Icon name="plus" />
          Inversión
        </Button>
        <Modal open={this.state.open} size={"tiny"} onClose={this.close}>
          <Modal.Header>Crear Inversión</Modal.Header>
          <Modal.Content>
            <Form
              loading={this.state.loading}
              error={this.state.error}>
              <Form.Field>
                <label>Seleccionar proyecto</label>
                <Dropdown
                  name="listingId"
                  placeholder="Proyecto"
                  fluid
                  search
                  selection
                  options={this.state.options}
                  onChange={this.onDropdownChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Cantidad</label>
                <input 
                  value={this.state.name} 
                  placeholder="Ej. 200"
                  onChange={this.handleChange}
                  name="amount"/>
              </Form.Field>
              <Message error header="Error" content={this.state.message} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button.Group size="large">
              <Button onClick={this.close} color="red">Cancelar</Button>
              <div className="or" data-text="o"></div>
              <Button type="submit" onClick={this.handleCreate} positive>Guardar</Button>
            </Button.Group>
          </Modal.Actions>
        </Modal>
        <SweetAlert
          show={this.state.showAlert}
          title="Inversión Creada"
          type="success"
          onConfirm={() => this.setState({ showAlert: false })}
        />
      </React.Fragment>
    );
  }
}

export default NewInvestmentModal;