import { LocalStorage } from "./storage";
import { JWT_STORAGE_KEY } from "./constants";

const getToken = () => LocalStorage.getItem(JWT_STORAGE_KEY);

const hasValidToken = () => {
  if (hasToken()) {
    const token = parseJwt(getToken());
    const res = Date.now() / 1000 - token.exp;
    if (res < 0) return true;
    else return false;
  }
};

const parseJwt = token => {
  var base64Url = token.split(".")[1];
  var base64 = decodeURIComponent(
    atob(base64Url)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(base64);
};

const deleteToken = () => LocalStorage.removeItem(JWT_STORAGE_KEY);

const hasToken = () => !!getToken();

export { getToken, deleteToken, hasToken, hasValidToken };
