import React, { Component } from "react";
import SearchUser from "./SearchUser";
import UserSectionLayout from "./UserSectionLayout";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import "./style.css";

const GET_USERS = gql`
  query FetchUsers($emailOrUsername: String!) {
    users(emailOrUsername: $emailOrUsername) {
      email
      id
      balances {
        available
        in_funding
      }
      logins(last: 5) {
        id
        timestamp
      }
      investments {
        amount
        id
        status_id
        investment_date
        initial_annual_rate
        current_annual_rate
        total_payment
        listing {
          id
          number_of_panels
          energy_production
          name
        }
      }
      withdrawals {
        id
        status_id
        amount
      }
      transactions {
        timestamp
        type
        amount
        available_balance
        account_value
      }
    }
  }
`;

const UserData = ({ loading, error, data }) => {
  if (loading) return <UserSectionLayout status="loading" />;
  if (error)
    return <UserSectionLayout message={error.message} status="error" />;
  if (data) {
    if (data.users.length < 1) return <UserSectionLayout status="not_found" />;
    //if the data is loaded:
    return <UserSectionLayout userData={data.users[0]} status="ok" />;
  } else {
    return null;
  }
};

class Users extends Component {
  state = { userSearchQuery: "" };

  setUserSearchQuery = userSearchQuery => {
    this.setState({ userSearchQuery });
  };

  render() {
    return (
      <div className="user_section pushed">
        <SearchUser setUserSearchQuery={this.setUserSearchQuery} />
        <Query
          query={GET_USERS}
          variables={{ emailOrUsername: this.state.userSearchQuery }}
          skip={!this.state.userSearchQuery}
          fetchPolicy={"network-only"}
        >
          {UserData}
        </Query>
      </div>
    );
  }
}

export default Users;
