import React, { Component } from "react";
import { hasValidToken } from "../../utils/auth.js";
import { Confirm, Sidebar, Segment } from "semantic-ui-react";
import MainMenu from "./Menu.js";
import DashboardPage from "../Dashboard";
import UsersPage from "../Users";
import SolarCustomers from "../SolarCustomers";
import { ListingsPage, NewListingPage } from "../Listings";
import { Switch, Route } from "react-router-dom";
import Logout from "../../utils/Logout";
import "./style.css";

class Layout extends Component {
  state = { selection: "principal", expired: false };
  componentDidMount() {
    this.interval = setInterval(() => this.handleExpired(), 15000);
  }

  handleExpired = () => {
    if (!hasValidToken()) {
      this.show();
    }
  };

  handleMenuItem = selection => this.setState({ selection });
  show = () => this.setState({ expired: true });
  handleCancel = () => this.setState({ expired: false });
  handleConfirm = () => window.location.reload();
  render() {
    return (
      <Sidebar.Pushable as={Segment} className="full-height">
        <MainMenu selection={this.handleMenuItem} />
        <Sidebar.Pusher>
          <Confirm
            open={this.state.expired}
            cancelButton="Cancelar"
            confirmButton="Volver a iniciar sesión"
            content="Tu sesión ha expirado"
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
          />
          <Switch>
            <Route exact path="/" component={DashboardPage} />
            <Route path="/users" component={UsersPage} />
            <Route exact path="/listings" component={ListingsPage} />
            <Route exact path="/solar-customers" component={SolarCustomers} />
            <Route path="/listings/new" component={NewListingPage} />
            <Route path="/logout" component={Logout} />
          </Switch>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

export default Layout;
