import React, { Component } from "react";
import { Message, Header, Button, Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";

import Request from "../../utils/api/axios";

import { FUNDING_STATUS } from "../../utils/constants";
import "./styles.css";

class NewListing extends Component {
  state = {
    name: "",
    description: "",
    total_investment: "",
    initial_annual_rate: "",
    annual_rate_incr: "",
    principal_return_rate: "",
    construction_time: "",
    number_of_panels: "",
    energy_production: "",
    env_impact_co2: "",
    loading: false,
    error: false,
    message: "",
    showAlert: false,
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleCreate = () => {
    this.setState({ loading: true });
    Request.post("listings", {
      name: this.state.name,
      description: this.state.description,
      total_investment: this.state.total_investment,
      initial_annual_rate: this.state.initial_annual_rate / 100,
      avg_annual_rate: this.state.initial_annual_rate / 100,
      annual_rate_incr: this.state.annual_rate_incr / 100,
      principal_return_rate: this.state.principal_return_rate / 100,
      construction_time: this.state.construction_time,
      number_of_panels: this.state.number_of_panels,
      energy_production: this.state.energy_production,
      env_impact_co2: this.state.env_impact_co2,
      funding_status_id: FUNDING_STATUS.IN_FUNDING,
      operation_status_id: 0,
    })
      .then(data => {
        this.setState({
          loading: false,
          error: false,
          showAlert: true,
        });
      })
      .catch(err => {
        let message = "Error en el servicio";
        if (err.response.status === 404) {
          message = "Verifica tus credenciales";
        } else if (err.response.status === 400) {
          message = err.response.data;
        }
        this.setState({
          loading: false,
          error: true,
          message,
        });
      });
  };

  render() {
    return (
      <div className="main_container">
        <Header as="h3">Nuevo Listing</Header>
        <Form loading={this.state.loading} error={this.state.error}>
          <Form.Field>
            <label>Nombre</label>
            <input
              value={this.state.name}
              placeholder="Ej. Cuarto de Kilo"
              onChange={this.handleChange}
              name="name"
            />
          </Form.Field>
          <Form.TextArea
            label="Descripción"
            value={this.state.description}
            onChange={this.handleChange}
            name="description"
            placeholder="Ingresa la descripción del listado de inversión..."
          />
          <Form.Group>
            <Form.Field width={4}>
              <label>Inversión Total</label>
              <input
                value={this.state.total_investment}
                onChange={this.handleChange}
                name="total_investment"
                placeholder="Ej. 100000"
              />
            </Form.Field>
            <Form.Input
              label="Tasa Anual (%)"
              name="initial_annual_rate"
              value={this.state.initial_annual_rate}
              onChange={this.handleChange}
              placeholder="Ej. 13"
              width={4}
            />
            <Form.Input
              label="Incremento Anual (%)"
              name="annual_rate_incr"
              value={this.state.annual_rate_incr}
              onChange={this.handleChange}
              placeholder="Ej. 3"
              width={4}
            />
            <Form.Input
              label="Retorno a la Venta (%)"
              name="principal_return_rate"
              value={this.state.principal_return_rate}
              onChange={this.handleChange}
              placeholder="Ej. 115"
              width={4}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Tiempo Construcción (días)"
              name="construction_time"
              value={this.state.construction_time}
              onChange={this.handleChange}
              placeholder="Ej. 14"
              width={4}
            />
            <Form.Input
              label="# de Paneles"
              name="number_of_panels"
              value={this.state.number_of_panels}
              onChange={this.handleChange}
              placeholder="Ej. 13"
              width={4}
            />
            <Form.Input
              label="Producción (kW)"
              name="energy_production"
              value={this.state.energy_production}
              onChange={this.handleChange}
              placeholder="Ej. 5.67"
              width={4}
            />
            <Form.Input
              label="Impacto Ambiental (Kgs CO2)"
              name="env_impact_co2"
              value={this.state.env_impact_co2}
              onChange={this.handleChange}
              placeholder="Ej. 2230"
              width={4}
            />
          </Form.Group>
          <Message error header="Error" content={this.state.message} />
          <Button.Group floated="right" size="large">
            <Button as={Link} to="/listings" color="red">
              Cancelar
            </Button>
            <div className="or" data-text="o"></div>
            <Button type="submit" onClick={this.handleCreate} positive>
              Guardar
            </Button>
          </Button.Group>
          <SweetAlert
            show={this.state.showAlert}
            title="Listing Created"
            type="success"
            onConfirm={() => this.setState({ showAlert: false })}
          />
        </Form>
      </div>
    );
  }
}

export default NewListing;
