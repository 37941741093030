import React, { Fragment } from "react";
import CurrencyFormat from "react-currency-format";
import { Segment, Grid } from "semantic-ui-react";
const BehaviorSegment = ({ behavior, loading }) => (
  <Fragment>
    <Segment loading={loading} textAlign="center">
      <h3>Comportamiento</h3>
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={8}>
            <h4>Capital Fondeado</h4>
            <span className="behavior_data">
              <CurrencyFormat
                value={behavior.funded}
                displayType="text"
                thousandSeparator={true}
                prefix="$"
              />
            </span>
          </Grid.Column>
          <Grid.Column width={8}>
            <h4>Capital Retirado</h4>
            <span className="behavior_data">
              <CurrencyFormat
                value={behavior.withdrawals}
                displayType="text"
                thousandSeparator={true}
                prefix="$"
              />
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h4>Valor cuenta activa promedio</h4>
            <span className="behavior_data">
              <CurrencyFormat
                value={behavior.account_value.toFixed(2)}
                displayType="text"
                thousandSeparator={true}
                prefix="$"
              />
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <h4>Numero de cuentas</h4>
            <span className="behavior_data">{behavior.users}</span>
          </Grid.Column>
          <Grid.Column width={8}>
            <h4>Numero de cuentas activas</h4>
            <span className="behavior_data">{behavior.active_users}</span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </Fragment>
);

export default BehaviorSegment;
