import React, { Component } from "react";
import { Modal, Button, Icon, Form, Message } from "semantic-ui-react";
import SweetAlert from "sweetalert-react";

import Request from "../../utils/api/axios";

import { WITHDRAWAL_STATUS } from "../../utils/constants";

class NewTransactionModal extends Component {
  state = {
    open: false,
    loading: false,
    error: false,
    amount: "",
    numeric_reference: "",
    concept: "",
    message: "",
    showAlert: false,
  };

  open = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleCreate = () => {
    this.setState({ loading: true });
    let route = "users/" + this.props.data.id + "/withdrawals";
    Request.post(route, {
      amount: this.state.amount,
      numeric_reference: this.state.numeric_reference,
      concept: this.state.concept,
      status_id: WITHDRAWAL_STATUS.COMPLETED,
    })
      .then(data => {
        this.setState({
          loading: false,
          error: false,
          open: false,
          showAlert: true,
        });
      })
      .catch(err => {
        let message = "Error en el servicio";
        if (err.response.status === 404) {
          message = "Verifica tus credenciales";
        } else if (err.response.status === 400) {
          message = err.response.data;
        }
        this.setState({
          loading: false,
          error: true,
          message,
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Button onClick={this.open} circular floated="right" color="green">
          <Icon name="plus" />
          Retiro
        </Button>
        <Modal open={this.state.open} size={"tiny"} onClose={this.close}>
          <Modal.Header>Crear Movimiento</Modal.Header>
          <Modal.Content>
            <Form loading={this.state.loading} error={this.state.error}>
              <Form.Field>
                <label>Cantidad</label>
                <input
                  value={this.state.name}
                  placeholder="Ej. 200"
                  onChange={this.handleChange}
                  name="amount"
                />
              </Form.Field>
              <Form.Field>
                <label>Referencia Numérica</label>
                <input
                  value={this.state.name}
                  placeholder="Ej. 1234567"
                  onChange={this.handleChange}
                  name="numeric_reference"
                />
              </Form.Field>
              <Form.Field>
                <label>Concepto</label>
                <input
                  value={this.state.name}
                  placeholder="Ej. Retiro Lumio"
                  onChange={this.handleChange}
                  name="concept"
                />
              </Form.Field>
              <Message error header="Error" content={this.state.message} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button.Group size="large">
              <Button onClick={this.close} color="red">
                Cancelar
              </Button>
              <div className="or" data-text="o"></div>
              <Button type="submit" onClick={this.handleCreate} positive>
                Guardar
              </Button>
            </Button.Group>
          </Modal.Actions>
        </Modal>
        <SweetAlert
          show={this.state.showAlert}
          title="Transacción Creada"
          type="success"
          onConfirm={() => this.setState({ showAlert: false })}
        />
      </React.Fragment>
    );
  }
}

export default NewTransactionModal;
