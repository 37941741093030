import axios from "axios";
import React, { Component } from "react";
import { Grid, Button, Form, Message, Image } from "semantic-ui-react";
import { JWT_STORAGE_KEY } from "../../utils/constants.js";
import { LocalStorage } from "../../utils/storage.js";
import "./style.css";
import { withRouter } from "react-router-dom";
import logo from "../../images/lumio_logo.png";

function emailIsValid(email) {
  return /\S+@\S+\.\S+/.test(email);
}

class LogIn extends Component {
  state = {
    username: "",
    password: "",
    loading: false,
    error: false,
    message: "",
    userError: false,
    passError: false,
  };
  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  login = () => {
    this.setState({ loading: true });
    axios
      .post(`${process.env.REACT_APP_API_BASE_ENDPOINT}/auth/login`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        email: this.state.username,
        password: this.state.password,
      })
      .then(res => res.data)
      .then(data => {
        LocalStorage.setItem(JWT_STORAGE_KEY, data.token);
        window.location.reload();
      })
      .catch(error => {
        let message = "Error en el servicio";
        if (error.response.status === 404) {
          message = "Verifica tus credenciales";
        }
        this.setState({
          loading: false,
          error: true,
          message,
        });
      });
  };

  validate = () => {
    let message = "";
    if (!emailIsValid(this.state.username)) {
      message += `Usa un correo electronico valido`;
      this.setState({ userError: true });
    } else if (!!!this.state.username) {
      message += `Debes ingresar un correo electronico`;
      this.setState({ userError: true });
    }
    if (!!!this.state.password) {
      message += ` - Debes ingresar una contraseña`;
      this.setState({ passError: true });
    }
    if (!!message) {
      this.setState({ message, error: true });
      return false;
    } else {
      return true;
    }
  };

  handleLogin = () => {
    if (this.validate()) this.login();
  };

  render() {
    return (
      <Grid verticalAlign="middle" centered columns={2} className="login">
        <Grid.Column>
          <Image className="login_logo" src={logo} size="small" name="logo" centered />
          <Form
            className="login_form"
            loading={this.state.loading}
            error={this.state.error}
          >
            <Form.Field error={this.state.userError}>
              <label>Correo Electronico</label>
              <input
                placeholder="Correo Electronico"
                value={this.state.username}
                onChange={this.handleChange}
                id="username"
              />
            </Form.Field>
            <Form.Field error={this.state.passError}>
              <label>Password</label>
              <input
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleChange}
                id="password"
              />
            </Form.Field>
            <Message error header="Error" content={this.state.message} />
            <button className="login_button" onClick={this.handleLogin}>
              Iniciar Sesión
            </button>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withRouter(LogIn);
