import React from "react";
import { Segment } from "semantic-ui-react";
import { Doughnut as DoughnutChart } from "react-chartjs-2";
import { INVESTMENT } from "../../utils/constants.js";
import CurrencyFormat from "react-currency-format";

const prepareChartData = values => {
  const labels = values.map(value => value.name);
  const data = values.map(value => value.amount);
  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: ["#FFC001", "#3ba834", "#374bce"],
        hoverBackgroundColor: ["#CC9900", "#308629", "#2C3CA4"],
      },
    ],
  };
};
const sumValues = values => values.reduce((sum, curr) => sum + curr.amount, 0);

const UserAccountValue = ({ data }) => {
  let { balances, investments } = data;

  if (balances.length === 0) {
    balances = [{ available: 0, in_funding: 0 }];
  }
  if (investments.length === 0) {
    investments = [
      { amount: 0, listing: { energy_production: 0, number_of_panels: 0 } },
    ];
  } else {
    investments = investments.filter(
      ({ status_id }) => status_id === INVESTMENT.COMPLETED
    );
  }
  const userBalances = [
    { name: "Activo", amount: sumValues(investments) },
    { name: "Líquido", amount: balances[0].available },
    { name: "Fondeo", amount: balances[0].in_funding },
  ];

  const options = {
    legend: {
      display: true,
      position: "right",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.labels[tooltipItem.index];
          if (label) {
            label += ": ";
          }
          label += new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(data.datasets[0].data[tooltipItem.index]);
          return label;
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Segment.Group horizontal className="no_border no_padding">
        <Segment className="no_padding chart_segment">
          <Segment.Group className="no_border">
            <Segment className="no_padding">
              <h4>VALOR DE CUENTA</h4>
              <b>
                <CurrencyFormat
                  value={sumValues(userBalances)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </b>
            </Segment>
            <Segment>
              <div className="pie_chart">
                <DoughnutChart
                  data={prepareChartData(userBalances)}
                  options={options}
                />
              </div>
            </Segment>
          </Segment.Group>
        </Segment>
        <Segment className="investment_segment">
          <div className="investment_data border_right">
            <span>Proyectos</span>:{investments.length}
          </div>
          <div className="investment_data border_right">
            <span>Paneles:</span>
            {investments.reduce(
              (sum, { listing }) => sum + listing.number_of_panels,
              0
            )}
          </div>
          <div className="investment_data">
            <span>Potencia KW:</span>
            {investments
              .reduce((sum, { listing }) => sum + listing.energy_production, 0)
              .toFixed(2)}
          </div>
        </Segment>
      </Segment.Group>
    </React.Fragment>
  );
};

export default UserAccountValue;
